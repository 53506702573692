import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import HeroImage from "../components/HeroImage/HeroImage";
import heroBox from "../images/hepatitis-b-screening-diagnosis/hero-box.svg";
import heroBoxB from "../images/hepatitis-b-screening-diagnosis/hero-box-b.svg";
import heroBoxBWithoutAnimation from "../images/hepatitis-b-screening-diagnosis/hero-box-b-without-animation.svg";
import heroBoxWithoutAnimation from "../images/hepatitis-b-screening-diagnosis/hero-box-without-animation.svg";
import Subheading from "../components/Subheading/Subheading";
import HorizontalRule from "../components/HorizontalRule";
import References from "../components/References/References";
import Slab from "../components/Slab";
import BlockQuote from "../components/BlockQuote/BlockQuote";
import Strong from "../components/Strong";
import Paragraph from "../components/Paragraph";
import { ReactComponent as VaccineIcon } from "../images/icons/vaccine.svg";
import { ReactComponent as IdentifyIcon } from "../images/icons/personnel-search.svg";
import { ReactComponent as ExperimentIcon } from "../images/icons/experiment.svg";
import { ReactComponent as CounselIcon } from "../images/icons/counsel.svg";
import { ReactComponent as ReferIcon } from "../images/icons/refer.svg";
import Link from "../components/Link/Link";
import NextPage from "../components/Link/NextPage";
import AssetCardGroup from "../components/AssetCard/AssetCardGroup";
import ScreeningGuidelines from "../partials/AssetCards/ScreeningGuidelines";
import InterpretationTable from "../partials/Tables/InterpretationTable";
import VaccinationScheduleTable from "../partials/Tables/VaccinationScheduleTable";
import Abbr from "../components/Abbr/Abbr";
import Footnotes from "../components/Footnotes";
import AbbrList from "../components/Abbr/AbbrList";
import ReferenceItem from "../components/References/ReferenceItem";
import tracker from "../tracker";
import ImageWrapper from "../components/ImageWrapper";
import Parallax from "../components/Parallax/Parallax";

const ScreeningAndDiagnosisPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: { eq: "hepatitis-b-screening-diagnosis/hero.jpg" }
      ) {
        ...HeroQuery
      }
      herob: file(
        relativePath: { eq: "hepatitis-b-screening-diagnosis/hero-b.png" }
      ) {
        ...HeroQuery
      }
      circle1: file(
        relativePath: { eq: "hepatitis-b-screening-diagnosis/circle-1.png" }
      ) {
        ...TextQuery
      }
      circle2: file(
        relativePath: { eq: "hepatitis-b-screening-diagnosis/circle-2.png" }
      ) {
        ...TextQuery
      }
      circle3: file(
        relativePath: { eq: "hepatitis-b-screening-diagnosis/circle-3.png" }
      ) {
        ...TextQuery
      }
      slabPerson: file(
        relativePath: {
          eq: "hepatitis-b-screening-diagnosis/3-0-slab-person.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 262) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      routesDesktop: file(
        relativePath: {
          eq: "hepatitis-b-screening-diagnosis/HBV-routes-desktop.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      routesMobile: file(
        relativePath: {
          eq: "hepatitis-b-screening-diagnosis/HBV-routes-mobile.png"
        }
      ) {
        ...TextQuery
      }
      slabMotherAndChild: file(
        relativePath: {
          eq: "hepatitis-b-screening-diagnosis/3-0-slab-mother-and-child.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      footerMan: file(relativePath: { eq: "footer-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      page: trackingJson(id: { eq: "hepatitis-b-screening-diagnosis" }) {
        subject
      }
      learnAboutManaging: trackingJson(id: { eq: "learnAboutManaging" }) {
        ...TrackingQuery
      }
      treatingChronicPatients: trackingJson(
        id: { eq: "treatingChronicPatients" }
      ) {
        ...TrackingQuery
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);

  return (
    <Layout>
      <SEO
        title="How to Screen and Diagnose Hepatitis B"
        description="Learn about CDC-recommended hep B tests and how to interpret results to diagnose, or to determine if your patient warrants hepatitis B vaccination."
      />
      <HeroImage
        headLine="How to screen for and diagnose HBV."
        content={
          <span>
            Ending hep B begins with screening and diagnosing your at-risk
            patients. With just one blood draw, you can measure three virologic
            markers of hepatitis B virus infection.<sup>1</sup>
          </span>
        }
        footer={
          <div className="text-black md:text-white md:-mt-8 lg:-mt-4">
            <Abbr term="HBV" title="hepatitis B virus." />
          </div>
        }
        fluidQuery={data.hero.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{
          desktop: {
            x: 70,
            y: 20,
          },
          mobile: {
            x: 70,
            y: 50,
          },
        }}
      />
      <HeroImage
        className="HeroImage--hide"
        headLine="How to screen for and diagnose HBV."
        content={
          <span>
            Ending hep B begins with screening and diagnosing your at-risk
            patients. With just one blood draw, you can measure three virologic
            markers of hepatitis B virus infection.<sup>1</sup>
          </span>
        }
        footer={
          <div className="text-black md:text-white md:-mt-8 lg:-mt-4">
            <Abbr term="HBV" title="hepatitis B virus." />
          </div>
        }
        fluidQuery={data.herob.childImageSharp.fluid}
        boxSrc={heroBoxB}
        boxSrcWithoutAnimation={heroBoxBWithoutAnimation}
        imagePosition={{
          desktop: {
            x: 100,
            y: 0,
          },
          mobile: {
            x: 100,
            y: 50,
          },
        }}
      />

      <Slab className="-mt-16 md:-mt-12 lg:-mt-16" paddingBottom="pb-0">
        <div className="md:relative container-fluid md:mx-auto -mx-8">
          <div className="bg-grey md:rounded-lg mt-12 md:-mt-10 p-8 pt-12">
            <div className="text-grey-dark">
              <Subheading>CDC-recommended Hep B screening tests</Subheading>
              <div className="md:flex">
                <div className="w-full md:w-1/3 pb-2 pr-8 py-4">
                  <Img
                    fluid={data.circle1.childImageSharp.fluid}
                    alt="Number one"
                    className="w-10"
                  />
                  <hgroup className="pt-2">
                    <h3 className="font-bold antialiased">HBsAg</h3>
                    <div className="font-bold antialiased text-grey-dark">
                      Hepatitis B surface antigen
                    </div>
                    <div className="font-bold antialiased text-grey-dark italic">
                      Hallmark of infection
                    </div>
                  </hgroup>
                  <Paragraph className="text-sm">
                    HBsAg is a protein on the surface of HBV; it can be detected
                    in high levels in serum during acute or chronic HBV
                    infection. The presence of HBsAg indicates that the person
                    is infectious. The body normally produces antibodies to
                    HBsAg as part of the normal immune response to infection.
                    <sup>1</sup>
                  </Paragraph>
                </div>
                <div className="w-full md:w-1/3 pr-8 py-4">
                  <Img
                    fluid={data.circle2.childImageSharp.fluid}
                    alt="Number two"
                    className="w-10"
                  />
                  <hgroup className="pt-2">
                    <h3 className="font-bold antialiased">Anti-HBs</h3>
                    <div className="font-bold antialiased text-grey-dark">
                      Hepatitis B surface antibody
                    </div>
                    <div className="font-bold antialiased text-grey-dark italic">
                      Marker of immunity<sup>a</sup>
                    </div>
                  </hgroup>
                  <Paragraph className="text-sm">
                    The presence of anti-HBs is generally interpreted as
                    indicating recovery and immunity from HBV infection.
                    Anti-HBs also develops in a person who has been successfully
                    vaccinated against hepatitis B.<sup>1</sup>
                  </Paragraph>
                </div>
                <div className="w-full md:w-1/3 py-4">
                  <Img
                    fluid={data.circle3.childImageSharp.fluid}
                    alt="Number three"
                    className="w-10"
                  />
                  <hgroup className="pt-2">
                    <h3 className="font-bold antialiased">Anti-HBc</h3>
                    <div className="font-bold antialiased text-grey-dark">
                      Hepatitis B core antibody
                    </div>
                    <div className="font-bold antialiased text-grey-dark italic">
                      Marker of prior exposure
                    </div>
                  </hgroup>
                  <Paragraph className="text-sm">
                    Anti-HBc appears at the onset of symptoms in acute HBV
                    infection and persists for life. The presence of anti-HBc
                    indicates previous or ongoing infection with HBV for an
                    undefined time frame.<sup>1</sup>
                  </Paragraph>
                </div>
              </div>
              <AbbrList>
                <Abbr term="Anti-HBc" title="antibody to HBcAg" />
                <Abbr term="Anti-HBs" title="antibody to HBsAg" />
                <Abbr
                  term="CDC"
                  title="Centers for Disease Control and
                Prevention"
                />
                <Abbr term="HBsAg" title="hepatitis B surface antigen." />
              </AbbrList>
              <Footnotes>
                <sup>a</sup>Through vaccination or recovery from previous HBV
                infection.
              </Footnotes>
              <HorizontalRule className="mt-2 border-grey-dark" />
              <div className="font-bold antialiased text-grey-dark py-2 ">
                Note: All three tests are required to correctly determine next
                steps.
              </div>
            </div>
          </div>
        </div>
      </Slab>
      <Slab name="insurance">
        <div className="md:flex">
          <div className="w-full md:w-3/5 md:pr-4">
            <Subheading className="w-full">
              HBV Screening is covered by most insurance plans
            </Subheading>
            <Paragraph>
              <Strong color="black-dark" size="lg">
                The USPSTF has given a grade B recommendation for HBV screening
                in persons at high risk for infection<sup>2,b</sup>
              </Strong>
            </Paragraph>
            <Paragraph>
              <Strong>Most private health insurance plans</Strong> cover
              recommended preventive services with an A or B recommendation
              without cost-sharing<sup>3</sup>
            </Paragraph>
            <Paragraph>
              <Strong>Medicare</Strong> typically covers all USPSTF-recommended
              services with an A or B recommendation without cost-sharing
              <sup>3</sup>
            </Paragraph>
            <Paragraph>
              <Strong>Medicaid</Strong> programs are either required to cover
              services without cost-sharing or incentivised to cover all
              USPSTF-recommended services with an A or B recommendation without
              cost-sharing<sup>3</sup>
            </Paragraph>
            <Footnotes className="my-4">
              <Abbr
                term="USPSTF"
                title="U.S. Preventive Services Task Force."
              />
              <div className="h-2"></div>
              <sup>b</sup>Confirm with patient's insurance plan to ensure
              reimbursement eligibility.
            </Footnotes>
          </div>
          <div className="w-full md:w-2/5 md:pl-4 pt-4 md:pt-0">
            <BlockQuote
              attribution="&ndash;Ruma Rajbhandari, MD, MPH and Raymond T. Chung, MD"
              cite={
                <span>
                  Annals of Internal Medicine<sup>4</sup>
                </span>
              }
              padded
            >
              &ldquo;Tests for HBV infection are widely available, cheap, and
              accurate.&rdquo;
            </BlockQuote>
          </div>
        </div>
      </Slab>
      <HorizontalRule color="grey-medium hidden md:block" />
      <div className="pt-0 md:pt-12 pb-8">
        <div className="container">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-3/4 mt-2">
              <Subheading className="w-full">
                Interpretation and management based on screening test results
                (AASLD, ACP/CDC)
              </Subheading>
              <Paragraph className="uppercase pb-4">
                Possible HBV serologic test results<sup>5,6</sup>
              </Paragraph>
              <InterpretationTable />
              <AbbrList>
                <Abbr
                  term="AASLD"
                  title="American Association for the Study of Liver Diseases"
                />
                <Abbr term="Anti-HBs" title="antibody to HBsAg" />
                <Abbr term="HBsAg" title="hepatitis B surface antigen" />
                <Abbr term="HCC" title="hepatocellular carcinoma." />
              </AbbrList>
              <Footnotes>
                <sup>c</sup>Anti-HBc refers to total anti-HBc.<sup>7</sup>
                <br />
                <sup>d</sup>Patient is chronically infected if HBsAg+ for &ge;6
                months; patients with acute infection will be positive for
                anti-HBc IgM.<sup>7</sup>
                <br />
                <sup>e</sup>Patients undergoing immunosuppressive therapy or
                treatment with direct-acting antivirals for HCV coinfection
                should be monitored for HBV reactivation.<sup>5</sup>
                <br />
                <sup>f</sup>Patients with cirrhosis should be screened every 6
                months for hepatocellular carcinoma per the AASLD guidance.
                <sup>5</sup>
              </Footnotes>
              <Paragraph className="pt-8">
                Following an initial evaluation, all patients with chronic HBV
                infection should be monitored to assess liver disease
                progression and the need for treatment. Not all chronically
                infected patients are indicated for treatment, but all will
                require lifelong monitoring.<sup>7,8</sup>{" "}
              </Paragraph>
              <Link
                to="/hepatitis-b-management"
                className="text-green underline font-bold antialiased pt-8 block"
                tracking={tracker(data.learnAboutManaging)}
              >
                Learn about managing chronic hepatitis B.
              </Link>
            </div>
            <div className="w-full md:w-1/4 self-end">
              <div className="px-16 md:px-0 -mb-8 pt-8">
                <Parallax speed={0.5} percentage={1}>
                  <ImageWrapper maxWidth={234}>
                    <Img fluid={data.slabPerson.childImageSharp.fluid} />
                  </ImageWrapper>
                </Parallax>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slab className="bg-grey relative">
        <Subheading>Educate patients to prevent HBV transmission</Subheading>
        <Paragraph className="pb-4">
          Myths about HBV transmission abound. Educate your patients on the
          facts to help prevent the spread of HBV.
        </Paragraph>
        <div className="pt-4">
          <Strong color="black-dark" size="lg">
            How is HBV most commonly transmitted in the US?
          </Strong>
        </div>
        <ol className="text-black list-decimal list-inside">
          <li className="py-2">
            <Strong>Childbirth</Strong> - Perinatal transmission is the major
            route by which hepatitis B infection is perpetuated in endemic
            populations.<sup>9</sup>
          </li>
          <li className="py-2">
            <Strong>Sexual contact</Strong> - Both acute an chronic hepatitis B
            infection may also be transmitted through any type of exposure to
            bodily fluids, such as blood and semen.<sup>1</sup>
          </li>
        </ol>
        <Paragraph className="uppercase my-4">
          Routes of HBV transmission
        </Paragraph>
        <ImageWrapper left maxWidth={800}>
          <Img
            fluid={data.routesDesktop.childImageSharp.fluid}
            className="hidden md:block"
            alt="Graphic showing routes of HBV transmission"
          />
        </ImageWrapper>
        <ImageWrapper maxWidth={343}>
          <Img
            fluid={data.routesMobile.childImageSharp.fluid}
            className="md:hidden"
            alt="Graphic showing routes of HBV transmission"
          />
        </ImageWrapper>
        <HorizontalRule className="mt-4" />
        <Paragraph className="font-bold antialiased">
          Progression to CHB occurs in &gt;90% of infants, approximately 25%–50%
          of children aged 1–5 years, and &lt;5% of older children and adults.
          <sup>7</sup>
        </Paragraph>
      </Slab>
      <Slab name="vaccination">
        <div className="flex flex-col-reverse md:flex-row">
          <div className="w-full md:w-3/5 md:pr-8">
            <Subheading className="w-full">
              Vaccinate patients as recommended
            </Subheading>
            <Paragraph>
              Vaccination is the most effective way to help prevent HBV
              infection and its complications.<sup>6</sup>
            </Paragraph>
            <Paragraph className="uppercase">
              Populations recommended for HBV vaccination by the CDC
              <sup>10</sup>:
            </Paragraph>
            <ul className="text-black pt-4 pb-8">
              <li className="py-2">
                <Strong>
                  All Newborns<sup>g</sup>
                </Strong>
              </li>
              <li className="py-2">
                <Strong>All unvaccinated children</Strong> and adolescents
                &lt;19 years of age
              </li>
              <li className="py-2">
                <Strong>All unvaccinated adults</Strong> at risk for infection
                and those seeking protection from HBV infection
              </li>
              <li className="py-2">
                <Strong>Primary vaccination</Strong> consists of 3 intramuscular
                doses given at 0, 1, and 6 months;<sup>1</sup>A 2-dose series
                given at 0 and 1 months is also available for adults
                <sup>11</sup>
              </li>
              <li className="py-2">
                <Strong>A full 3-dose vaccine</Strong> series is associated with
                immunity in up to 90% of healthy adults<sup>11</sup>
              </li>
            </ul>
            <Footnotes>
              <Abbr
                term="CDC"
                title="Centers for Disease Control and Prevention."
              />
              <br />
              <sup>g</sup>Infants born to HBsAG+ mothers should also receive
              hepatitis B immune globulin by &le;12 hours of birth.<sup>10</sup>
            </Footnotes>
            <VaccinationScheduleTable />
            <div className="px-8 sm:px-16 -mb-12  md:hidden">
              <Parallax speed={-0.5} percentage={0.16}>
                <Img fluid={data.slabMotherAndChild.childImageSharp.fluid} />
              </Parallax>
            </div>
          </div>
          <div className="w-full md:w-2/5">
            <div className="flex flex-col justify-between h-full">
              <div className="bg-green rounded-lg p-6 mb-12 -mt-8 md:mt-0">
                <VaccineIcon />
                <Strong color="white" size="lg" className="block mt-8">
                  Screen for HBV infection before vaccination.<sup>10</sup>
                </Strong>
              </div>
              <div className="-mb-12 hidden md:block">
                <Parallax speed={-0.5} percentage={0.16}>
                  <ImageWrapper maxWidth={350}>
                    <Img
                      fluid={data.slabMotherAndChild.childImageSharp.fluid}
                    />
                  </ImageWrapper>
                </Parallax>
              </div>
            </div>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey-dark text-white relative">
        <Subheading className="text-white" lineColor="white">
          Implementing HBV infection screening protocols
        </Subheading>
        <Paragraph color="white">
          Consider implementing screening protocols in your practice to
          identify, follow, refer, and treat appropriately persons with CHB.
          Taking these steps can have a major impact on reducing complications
          associated with chronic hepatitis B.<sup>13</sup>
        </Paragraph>
        <Paragraph>
          <Strong color="white" size="md">
            Implement clinical protocols to:
          </Strong>
        </Paragraph>
        <div className="flex flex-col md:flex-row justify-between pt-4">
          <div className="w-full md:w-1/4 py-4 pr-4">
            <IdentifyIcon className="h-16" />
            <Paragraph color="white">
              <Strong color="white" size="md">
                Identify
              </Strong>{" "}
              high-risk patients in populations identified as high-risk by the
              AASLD, ACP and CDC, USPSTF
            </Paragraph>
          </div>
          <div className="w-full md:w-1/4 py-4 pr-4">
            <ExperimentIcon className="h-16" />
            <Paragraph color="white">
              <Strong color="white" size="md">
                Test
              </Strong>{" "}
              for the three markers of HBV infection through a simple blood draw
              <sup>1</sup>
            </Paragraph>
          </div>
          <div className="w-full md:w-1/4 py-4 pr-4">
            <CounselIcon className="h-16" />
            <Paragraph color="white">
              <Strong color="white" size="md">
                Counsel
              </Strong>{" "}
              infected patients about risks, including liver complications and
              transmission to others
            </Paragraph>
          </div>
          <div className="w-full md:w-1/4 py-4 pr-2">
            <ReferIcon className="h-16" />
            <Paragraph color="white">
              <Strong color="white" size="md">
                Refer or treat
              </Strong>{" "}
              patients as indicated by AASLD, AATA, and EASL guidelines
              <sup>5,14,15</sup>
            </Paragraph>
          </div>
        </div>
        <div className="text-white text-xs pt-8">
          <Abbr
            term="AASLD"
            title="American Association for the Study of Liver Diseases"
          />
          <Abbr term="AATA" title="Asian American Treatment Algorithm" />
          <Abbr term="ACP" title="American College of Physicians" />
          <Abbr
            term="CDC"
            title="Centers for Disease Control
          and Prevention"
          />
          <Abbr
            term="EASL"
            title="European Association for
          the Study of the Liver"
          />
          <Abbr
            term="USPSTF"
            title="U.S. Preventive Services
          Task Force."
          />
        </div>
      </Slab>
      <Slab>
        <NextPage
          to="/hepatitis-b-treatment"
          tracking={tracker(data.treatingChronicPatients)}
        >
          Treating your chronic hepatitis B patients
        </NextPage>
        <Subheading level={3}>
          Information for healthcare professionals
        </Subheading>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-3/4">
            <AssetCardGroup>
              <ScreeningGuidelines
                className="w-full md:w-2/3 md:pr-2"
                tracking={tracker(data.downloadTheGuide)}
              />
            </AssetCardGroup>
            <References>
              <ReferenceItem>
                Centers for Disease Control and Prevention. Hepatitis B. In:
                Hamborsky J, Kroger A, Wolfe S, eds.{" "}
                <em>
                  Epidemiology and Prevention of Vaccine - Preventable Diseases
                </em>
                . 13th ed. Washington, D.C.: Public Health Foundation;
                2015:149-174.
              </ReferenceItem>
              <ReferenceItem>
                U.S. Preventive Services Task Force Final Recommendation
                Statement: Hepatitis B Virus Infection: Screening. May 2014.
                https://www.uspreventiveservicestaskforce.org/Page/Document/UpdateSummaryFinal/hepatitis-b-virus-infection-screening-2014.
                Updated May 2014. Accessed October 8, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention. Viral Hepatitis
                Preventive Services.
                https://www.cdc.gov/nchhstp/preventionthroughhealthcare/preventiveservices/hepatitis.htm.
                Last reviewed May 2, 2018. Accessed October 8, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Rajbhandari R, Chung R. Screening for hepatitis B virus
                infection: A public health imperative. <em>Ann Intern Med</em>.
                2014;161:76-77.
              </ReferenceItem>
              <ReferenceItem>
                Terrault N, Lok A, McMahon B, et al. Update on prevention,
                diagnosis, and treatment of chronic hepatitis B: AASLD 2018
                hepatitis B guidance. <em>Hepatology</em>. 2018;67(4):1560-1599.
              </ReferenceItem>
              <ReferenceItem>
                Abara W, Qaseem A, Schillie S, et al. Hepatitis B vaccination,
                screening, and linkage to care: best practice advice from the
                American College of Physicians and the Centers for Disease
                Control and Prevention. <em>Ann Intern Med</em>.
                2017;167(11):794-804.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention.{" "}
                <em>MMWR Morb Mortal Wkly Rep</em>. 2008;57:1-20.
              </ReferenceItem>
              <ReferenceItem>
                Martin P, Lau DT, Nguyen MH, et al. A treatment algorithm for
                the management of chronic hepatitis B virus infection in the
                United States: 2015 update. <em>Clin Gastroenterol Hepatol</em>.
                2015;13(12):2071-2087.e16.
              </ReferenceItem>
              <ReferenceItem>
                World Health Organization. Hepatitis B.
                https://www.who.int/news-room/fact-sheets/detail/hepatitis-b.
                Updated July 18, 2019. Accessed October 15, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention. Hepatitis B
                Questions and Answers for Professionals. Hepatitis B Serology.
                https://www.cdc.gov/hepatitis/hbv/hbvfaq.htm. Last reviewed
                October 25, 2019. Accessed October 29, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention.{" "}
                <em>MMWR Morb Mortal Wkly Rep</em>. 2018;67:455-458.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention.{" "}
                <em>MMWR Morb Mortal Wkly Rep</em>. 2006;55:1-40.
              </ReferenceItem>
              <ReferenceItem>
                McMahon, BJ. Implementing evidenced-based practice guidelines
                for the management of chronic hepatitis B virus infection.{" "}
                <em>Am J Med</em>. 2008 Dec;121(12 Suppl):S45-52.
              </ReferenceItem>
              <ReferenceItem>
                Tong MJ, Pan CQ, Han SB, et al. An expert consensus for the
                management of chronic hepatitis B in Asian Americans.{" "}
                <em>Aliment Pharmacol Ther</em>. 2018;47(8):1181-1200.
              </ReferenceItem>
              <ReferenceItem>
                European Association for the Study of the Liver. EASL 2017
                clinical practice guidelines on the management of hepatitis B
                virus infection. <em>J Hepatol</em>. 2017;67(2):370-398.
              </ReferenceItem>
            </References>
          </div>
          <div className="w-full md:w-1/4 self-end h-48 md:h-auto pt-8">
            <div className="z:0 -mb-64 md:-mb-16 mx-16 sm:mx-32 md:mx-0 md:ml-4">
              <ImageWrapper maxWidth={308}>
                <Img fluid={data.footerMan.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </Slab>
    </Layout>
  );
};

export default ScreeningAndDiagnosisPage;
