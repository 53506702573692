import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import Table, {
  TableRow,
  TableCell,
  TableHeader,
} from "../../components/Table/Table";

const VaccinationScheduleTable: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <Table className="my-6">
      <TableRow header>
        <TableHeader className="text-left text-sm md:text-base">
          First dose
          <br />
          (0 month)
        </TableHeader>
        <TableHeader className="text-left text-sm md:text-base">
          Second dose
          <br />
          (1 month)
        </TableHeader>
        <TableHeader className="text-left text-sm md:text-base">
          Third dose
          <br />
          (6 month)
        </TableHeader>
      </TableRow>
      <TableRow>
        <TableCell className="border-b border-grey-light">
          30%-55% with protective immunity<sup>12</sup>
        </TableCell>
        <TableCell className="border-b border-grey-light">
          75% with protective immunity<sup>12</sup>
        </TableCell>
        <TableCell className="border-b border-grey-light">
          Up to 90% with protective immunity<sup>11</sup>
        </TableCell>
      </TableRow>
      <TableRow />
    </Table>
  );
};
export default VaccinationScheduleTable;
