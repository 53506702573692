import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import { ReactComponent as PlusIcon } from "../../images/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../images/icons/minus.svg";
import { ReactComponent as PlusOrMinusIcon } from "../../images/icons/plus-or-minus.svg";
import Link from "../../components/Link/Link";
import { useStaticQuery, graphql } from "gatsby";
import tracker from "../../tracker";

const InterpretationTable: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  const data = useStaticQuery(graphql`
    query {
      additionalTesting: trackingJson(id: { eq: "additionalTesting" }) {
        ...TrackingQuery
      }
      vaccinate: trackingJson(id: { eq: "vaccinate" }) {
        ...TrackingQuery
      }
    }
  `);

  return (
    <table className={className}>
      <tbody>
        <tr>
          <td
            colSpan={3}
            className="align-bottom text-black text-center px-0 leading-tight"
          >
            <div
              className="text-sm md:text-lg bg-grey"
              style={{ paddingTop: "2px" }}
            >
              Screening Tests
            </div>
          </td>
          <th className="w-4"></th>
          <th className="bg-grey-very-light text-left pl-2 py-3"></th>
          <th className="w-4"></th>
          <th className="bg-grey-very-light text-left pl-2 py-3"></th>
          <th className="w-4"></th>
        </tr>
        <tr className="bg-red text-white">
          <th className="text-xs md:text-base align-top h-12 pl-4 py-4 break-words">
            HBsAg
          </th>
          <th className="text-xs md:text-base align-top h-12 pl-2 py-4 break-words sm:break-normal">
            Anti‑
            <br className="sm:hidden" />
            HBs
          </th>
          <th className="text-xs md:text-base align-top h-12 pl-2 pr-4 py-4 break-words sm:break-normal">
            Anti‑
            <br className="sm:hidden" />
            HBc<sup>c</sup>
          </th>
          <th className="w-4"></th>
          <th className="text-xs md:text-base align-top bg-red-grey text-left px-2 py-4">
            Interpretation
          </th>
          <th className="w-4"></th>
          <th className="text-xs md:text-base align-top bg-red-grey text-left px-2 py-4">
            Management
          </th>
          <th className="w-4"></th>
        </tr>
        <tr className="bg-grey-very-light">
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <PlusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <MinusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <PlusIcon className="inline" />
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
          <td className="text-xs align-top text-black border-bottom-grey-light bg-grey text-left px-2 py-3">
            Acute or chronic infection<sup>d</sup>
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
          <td className="text-xs align-top border-bottom-grey-light bg-grey text-left px-2 py-3">
            <Link
              to="/hepatitis-b-management"
              className="text-green underline font-bold antialiased"
              tracking={tracker(data.additionalTesting)}
            >
              Additional testing and management needed
            </Link>
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
        </tr>
        <tr>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <MinusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <PlusOrMinusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <PlusIcon className="inline" />
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
          <td className="text-xs align-top text-black border-bottom-grey-light bg-grey-very-light text-left px-2 py-3">
            Exposure to HBV <br />
            Risk for reactivation<sup>e</sup>
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
          <td className="text-xs align-top text-black border-bottom-grey-light bg-grey-very-light text-left px-2 py-3">
            Follow up as appropriate<sup>f</sup>
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
        </tr>
        <tr>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <MinusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <PlusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black border-bottom-grey-light">
            <MinusIcon className="inline" />
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
          <td className="text-xs align-top text-black border-bottom-grey-light bg-grey-very-light text-left px-2 py-3">
            Immunity from vaccination
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
          <td className="text-xs align-top text-black border-bottom-grey-light bg-grey-very-light text-left px-2 py-3">
            No further testing required
          </td>
          <td className="border-bottom-grey-light">&nbsp;</td>
        </tr>
        <tr>
          <td className="text-center align-middle text-black">
            <MinusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black">
            <MinusIcon className="inline" />
          </td>
          <td className="text-center align-middle text-black">
            <MinusIcon className="inline" />
          </td>
          <td className="w-4">&nbsp;</td>
          <td className="text-xs align-top text-black bg-grey-very-light text-left ml-4 mr-2 px-2 py-3">
            Uninfected and not immune (at risk for infection)
          </td>
          <td className="w-4">&nbsp;</td>
          <td className="text-xs align-top text-black bg-grey-very-light text-left ml-4 mr-2 px-2 py-3 h-full">
            No further testing required;{" "}
            <Link
              to="/hepatitis-b-screening-diagnosis#vaccination"
              className="text-green underline font-bold antialiased"
              tracking={tracker(data.vaccinate)}
            >
              vaccinate
            </Link>
          </td>
          <td className="w-4">&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};
export default InterpretationTable;
